exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n:root {\n  /*\n   * Typography\n   * ======================================================================== */\n\n  --font-family-base: 'Segoe UI', 'HelveticaNeue-Light', sans-serif;\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  --max-content-width: 1000px;\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */\n\n  --screen-xs-min: 480px;  /* Extra small screen / phone */\n  --screen-sm-min: 768px;  /* Small screen / tablet */\n  --screen-md-min: 992px;  /* Medium screen / desktop */\n  --screen-lg-min: 1200px; /* Large screen / wide desktop */\n\n  --btn-primary-bg: #000;\n  --btn-boxShadow-bg: #000;\n  --btn-bordercolor-bg: #000;\n  --btn-text-color: #fff;\n  --btn-primary-color: #ffffff;\n  --common-color: #000;\n  --btn-secondary-bg: #ffffff;\n  --btn-secondary-color: #000;\n\n}\n\n._3Vk6J {\n  padding-left: 0;\n  padding-right: 0;\n  /* background-image: url('../../../public/static/admin-login-bg.jpg'); */\n  background: -webkit-gradient(linear,left top, right top,color-stop(0, #000),color-stop(50%, #000),color-stop(0, #fff),to(#fff));\n  background: -o-linear-gradient(left,#000 0,#000 50%,#fff 0,#fff);\n  background: linear-gradient(90deg,#000 0,#000 50%,#fff 0,#fff);\n  /* background-image: linear-gradient(315deg, #000000 0%, #414141 74%); */\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-attachment: scroll;\n  background-position: 50%;\n  min-height: 100vh;\n  height: 100%;\n  width: 100%;\n  overflow: hidden;\n\n}\n\n/* .container {\n  margin: 0 auto;\n  padding: 0 0 40px;\n  max-width: 400px;\n} */\n\n@media (max-width: 767px){\n  ._3Vk6J {\n \n    background: #000;\n   \n  \n  }\n  }", ""]);

// exports
exports.locals = {
	"root": "_3Vk6J"
};